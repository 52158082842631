import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import SectionImage from '@/calendesk/models/DTO/Response/SectionImage'
import Page from '@/calendesk/models/DTO/Response/Page'
import { PageType } from '@/calendesk/models/BuilderTypes'
import Section from '@/calendesk/models/DTO/Response/Section'
import { ButtonSizeType } from '@/calendesk/models/ButtonSizeType'
import { DateTimeFormats } from '@/calendesk/models/DateTimeFormats'
import { errorNotification } from '@/calendesk/prototypes/notifications'
import { BookingDynamicFormTypes } from '@/calendesk/models/BookingDynamicFormTypes'
import { BookingStatus } from '@/calendesk/models/DTO/Response/BookingStatus'
import StaticPage from '@/calendesk/models/StaticPage'
import { guessTimeZone } from '@/calendesk/tools/guessTimeZone'

declare global {
  interface Window {
    bh_id: any;
    bh_main_query: any;
    GoogleMapsController: any;
    defaultBillingDataType: number | undefined | null;
    replacedTranslations: any;
  }
}

export default Vue.extend({
  data () {
    return {
      buttonSizeType: ButtonSizeType,
      dateFormat: DateTimeFormats,
      dynamicFormTypes: BookingDynamicFormTypes,
      bookingStatuses: BookingStatus,
      debounceTimer: null as number | null,
      pageTypes: PageType
    }
  },
  computed: {
    ...mapGetters({
      draft: 'builder/getDraft',
      navbar: 'builder/getNavbar',
      footer: 'builder/getFooter',
      draftPages: 'builder/getDraftPages',
      isUserLogged: 'user/isUserLogged',
      showNavigationDrawer: 'drawer/showNavigationDrawer',
      showSideDrawer: 'drawer/showSideDrawer',
      getSideDrawerType: 'drawer/sideDrawerType',
      user: 'user/getUser',
      draftConfiguration: 'builder/getDraftConfiguration',
      appConfiguration: 'setup/getAppConfiguration',
      currentPage: 'builder/getCurrentPage',
      initialLoading: 'builder/getInitialLoading',
      services: 'service/getServices',
      employees: 'employee/getEmployees',
      userSubscriptions: 'subscription/getUserSubscriptions'
    }),
    navBarPageList (): Array<Page> {
      const pageList: Array<Page> = new Array<Page>()
      if (this.navbar && this.navbar.configuration && Array.isArray(this.navbar.configuration.wb_page_list__page_ids__)) {
        return this.pageListForGroup(this.navbar.configuration.wb_page_list__page_ids__)
      }

      return pageList
    },
    footerPageList (): Array<Page> {
      const pageList: Array<Page> = new Array<Page>()
      if (this.footer && this.footer.configuration && Array.isArray(this.footer.configuration.wb_page_list__page_ids__)) {
        return this.pageListForGroup(this.footer.configuration.wb_page_list__page_ids__)
      }

      return pageList
    },
    placeholderImageUrl (): any {
      return require('@/assets/placeholder.jpg')
    },
    isLoginHidden (): boolean {
      return !!this.draftConfiguration.wb_hide_login__checkbox__
    },
    isSignupHidden (): boolean {
      return !!this.draftConfiguration.wb_hide_signup__checkbox__
    },
    isHeaderHidden (): boolean {
      return !!this.draftConfiguration.wb_hide_header__checkbox__
    },
    isFooterHidden (): boolean {
      return !!this.draftConfiguration.wb_hide_footer__checkbox__
    },
    areEmployeesHidden (): boolean {
      return !!this.draftConfiguration.wb_hide_booking_employees__checkbox__
    },
    hasUserSubscriptions (): boolean {
      return this.user && this.user.subscriptions && this.user.subscriptions.length > 0
    },
    isTimeZoneHidden (): boolean {
      return !!this.draftConfiguration.wb_hide_time_zone__checkbox__
    }
  },
  beforeDestroy () {
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer)
    }
  },
  methods: {
    ...mapActions({
      logoutUserAndDestroySession: 'user/logoutUserAndDestroySession',
      setPreloader: 'setup/setPreloader',
      setRequestedPage: 'builder/setRequestedPage',
      setSectionsLoaderVisibility: 'builder/setSectionsLoaderVisibility',
      openDialog: 'dialog/openDialog',
      openDialog2: 'dialog/openDialog2',
      openConfirmDialog: 'dialog/openConfirmDialog',
      closeConfirmDialog: 'dialog/closeConfirmDialog',
      setConfirmDialogLoader: 'dialog/setConfirmDialogLoader',
      setNavigationDrawer: 'drawer/setNavigationDrawer',
      openSideDrawer: 'drawer/openSideDrawer',
      closeSideDrawer: 'drawer/closeSideDrawer',
      fetchCurrentPage: 'builder/fetchCurrentPage',
      setCurrentPage: 'builder/setCurrentPage',
      setInitialLoading: 'builder/setInitialLoading',
      initStripe: 'stripe/init',
      setAfterLoginAction: 'user/setAfterLoginAction'
    }),
    generateUUID (): string {
      const timestamp = Date.now() // Current timestamp in milliseconds
      const random = crypto.getRandomValues(new Uint8Array(16)) // Secure random values

      // Construct UUID using timestamp and randomness
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c, i) => {
        const r = (i === 0 ? timestamp : random[i % random.length]) & 0xf // Mix timestamp and random values
        const v = c === 'x' ? r : (r & 0x3) | 0x8 // Ensure correct UUID version and variant
        return v.toString(16) // Convert to hexadecimal
      })
    },
    debounce (callback: Function, delay: number): Function {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const context = this

      return function (this: any) {
        // eslint-disable-next-line prefer-rest-params
        const args = arguments

        if (context.debounceTimer) {
          clearTimeout(context.debounceTimer)
        }

        context.debounceTimer = setTimeout(() => {
          callback.apply(context, args)
          context.debounceTimer = null
        }, delay)
      }
    },
    replaceUrlsWithLinks (text: string): string {
      const urlRegex = /(https?:\/\/[^\s]+)/g
      return text.replace(urlRegex, '<a href="$1" target="_blank" rel="noreferrer noopener nofollow">$1</a>')
    },
    guessTimeZone (): string {
      return guessTimeZone()
    },
    pageListForGroup (group: Array<string>): Array<Page> {
      const pageList: Array<Page> = new Array<Page>()
      if (group && Array.isArray(group)) {
        group.forEach((uuid: string) => {
          const page: Page | undefined = this.draftPages.find((_: Page) => _.uuid === uuid)
          if (page && (this.isUserLogged || (!this.isUserLogged && !page.authOnly))) {
            pageList.push(page)
          }
        })
      }

      return pageList
    },
    getImageUrlFromSection (data: Section, slug: string): string | null {
      const logo: SectionImage | undefined = data.images.find((_: SectionImage) => _.slug === slug)
      return logo ? `${this.appConfiguration.imageBaseUrl}${logo.name}` : null
    },
    redirectToPageUuid (pageUuid: string) {
      const page = this.draftPages.find((page: Page) => page.uuid === pageUuid)

      if (page) {
        this.redirectToPage(page)
      } else {
        try {
          StaticPage.redirectToUuid(pageUuid)
        } catch (error) {
          errorNotification(null, error, false)
        }
      }
    },
    redirectToPage (page: Page): void {
      if (page.type === PageType.REDIRECT) {
        if (page.configuration?.open_in_new_tab) {
          window.open(page.action as string, '_blank', 'noopener')
        } else {
          if (page.action?.charAt(0) === '#') {
            // Special case for window scroll
            const classNameOrId = page.action.replace('#', '')
            let element = document.getElementById(classNameOrId)

            if (!element) {
              // Try with class
              element = document.querySelector('.' + classNameOrId)
            }

            if (element) {
              element.scrollIntoView({
                behavior: 'smooth'
              })
            } else {
              location.href = page.action as string
            }
          } else {
            location.href = page.action as string
          }
        }
        return
      }

      if (page.authOnly && !this.isUserLogged) {
        errorNotification('subpage_auth_required', {}, false)
        return
      }

      this.setSectionsLoaderVisibility(true)
      this.setRequestedPage(page).then(() => {
        this.setSectionsLoaderVisibility(false)
        if (page.type === PageType.MAIN_PAGE) {
          if (this.$router.currentRoute.path !== '/' && this.$router.currentRoute.path !== '') {
            this.$router.push({ path: '/' })
          }
        } else {
          if (page.type === PageType.BLOG_HANDY) {
            this.addBlogHandyIntegration()
          }

          if (this.$router.currentRoute.path !== '/' + page.route) {
            this.$router.push({ path: page.route ? '/' + page.route : '/' })
          }
        }

        window.scrollTo(0, 0)
      }).catch((error) => {
        if (error.response.status === 404) {
          window.location.replace(window.location.href)
        }
        errorNotification(null, error, false)
      })
    },
    redirectToMainPage (): void {
      const mainPage = this.draftPages.find((page: Page) => page.type === PageType.MAIN_PAGE)

      if (mainPage) {
        this.redirectToPage(mainPage)
      } else {
        errorNotification(null, null, false)
      }
    },
    redirectToBookings (): void {
      if (this.$router.currentRoute.path !== '/' + 'bookings') {
        this.$router.push({ name: 'bookings' })
      }
    },
    redirectToSubscription (subscriptionId: number): void {
      if (this.$router.currentRoute.path !== '/' + 'subscription') {
        this.$router.push({ name: 'subscription', params: { id: String(subscriptionId) } })
      }
    },
    redirectToSubscriptions (): void {
      if (this.$router.currentRoute.path !== '/' + 'subscriptions') {
        this.$router.push({ name: 'subscriptions' })
      }
    },
    addBlogHandyIntegration (): void {
      // Check if the script has already been added
      if (document.getElementById('blogHandyScript')) {
        // If the script is already added, manually trigger an update
        window.bh_main_query()
        return
      }

      if (this.currentPage && this.currentPage.type === PageType.BLOG_HANDY && this.currentPage.action) {
        window.bh_id = this.currentPage.action

        const script = document.createElement('script')
        script.src = 'https://www.bloghandy.com/api/bh_blogengine.js'
        script.id = 'blogHandyScript' // Assign an id to the script

        document.body.appendChild(script)
      }
    }
  }
})
