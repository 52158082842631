import { MutationTree } from 'vuex'
import { SetupState } from './types'
import Configuration from '@/calendesk/models/DTO/Response/Configuration'
import * as storage from '@/calendesk/tools/storage'

export const mutations: MutationTree<SetupState> = {
  SET_APP_CONFIGURATION (state, payload: Configuration) {
    state.appConfiguration = payload
  },
  SET_TRANSLATIONS (state, payload: object) {
    state.translations = payload
  },
  SET_PRELOADER (state, payload: boolean) {
    state.preloader = payload
  },
  SET_TENANT (state, tenant: string) {
    storage.local.setItem('tenant', tenant)
    state.tenant = tenant
  },
  SET_DRAFT_UUID (state, draftUuid: string) {
    storage.local.setItem('draft_uuid', draftUuid)
    state.draftUuid = draftUuid
  },
  SET_REGULATIONS (state, payload: string) {
    state.regulations = payload
  },
  SET_PRIVACY_POLICY (state, payload: string) {
    state.privacyPolicy = payload
  },
  SET_TIME_NOTATION (state, timeNotation: string) {
    storage.local.setItem('timeNotation', timeNotation)
    state.timeNotation = timeNotation
  },
  SET_INITIAL_SETUP (state, finished: boolean) {
    state.initialSetup = finished
  },
  SET_DEFAULT_TIMEZONE (state, timeZone: string) {
    state.defaultTimeZone = timeZone
    storage.local.setItem('timeZone', timeZone)
  }
}
