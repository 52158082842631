import moment from 'moment/moment'
import { replaceTimeZoneIfNeeded } from '@/calendesk/tools/timezoneHelper'
import timeZones from '@/calendesk/lib/timeZones'
import log from '@/calendesk/prototypes/log'
import store from '@/store'

export function guessTimeZone (): string {
  const storedTimeZone = store.getters['setup/getDefaultTimeZone']
  let timeZone = storedTimeZone ?? moment.tz.guess()
  timeZone = replaceTimeZoneIfNeeded(timeZone)

  if (!timeZones.includes(timeZone)) {
    log.notify('TimeZone from moment js missing', timeZone)
    timeZone = store?.getters['setup/getAppConfiguration']?.timeZone
  }

  return timeZone
}
