// Map problematic time zones to preferred formats
const timeZoneMapping: Record<string, string> = {
  'America/Buenos_Aires': 'America/Argentina/Buenos_Aires',
  'America/Catamarca': 'America/Argentina/Catamarca',
  'America/Cordoba': 'America/Argentina/Cordoba',
  'America/Jujuy': 'America/Argentina/Jujuy',
  'America/Mendoza': 'America/Argentina/Mendoza',
  'Europe/Kiev': 'Europe/Kyiv'
}

/**
 * Replace a time zone if it's in the mapping; otherwise, return the same time zone.
 * @param timeZone - The time zone to validate and replace if necessary
 * @returns The replaced or original time zone
 */
export function replaceTimeZoneIfNeeded (timeZone: string): string {
  return timeZoneMapping[timeZone] || timeZone
}
