export default function (description: string): string {
  if (!description) {
    return ''
  }

  const regex = /<a\s+(?:[^>]*?\s+)?href=(["'])(.*?)\1[^>]*>(.*?)<\/a>/gi
  description = description.replace(regex,
    '<a href="#" target="_blank" rel="noopener noreferrer nofollow" onclick="window.open(\'$2\', \'_blank\'); return false;">$3</a>'
  )
  return description
}
